export const articles = [
  // {
  //   id: 1,
  //   title: "Article 1",
  //   img: "https://picsum.photos/seed/picsum/200/300",
  //   author: "Author",
  //   date: "2021-09-01",
  //   content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
  // },
  // {
  //   id: 2,
  //   title: "Article 2",
  //   img: "https://picsum.photos/seed/picsum/200/300",
  //   author: "Author",
  //   date: "2021-09-01",
  //   content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
  // },
  // {
  //   id: 3,
  //   title: "Article 3",
  //   img: "https://picsum.photos/seed/picsum/200/300",
  //   author: "Author",
  //   date: "2021-09-01",
  //   content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
  // },
  // {
  //   id: 4,
  //   title: "Article 4",
  //   img: "https://picsum.photos/seed/picsum/200/300",
  //   author: "Author",
  //   date: "2021-09-01",
  //   content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
  // },
];
